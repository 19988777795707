const getCurrentRoute = (path) => {
  if (path === 'undefined') {
    return undefined;
  }

  const route = path.replace(/\/$/, '').split('/');
  const currentPath = route[route.length - 1];
  return currentPath;
};
export default getCurrentRoute;
