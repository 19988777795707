import React from 'react';
import {
  IconButton,
  ClickAwayListener,
  MenuItem,
  Popper,
  Grow,
  Paper,
  MenuList,
  Divider,
  Box,
  Avatar,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';

import pintunaAvatar from '../../../images/pintunaApp.png';
import { auth } from '../../../state';

// eslint-disable-next-line react/prop-types
const AvatarMenu = ({ imageUrl }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleLogout = async (e) => {
    handleClose(e);
    dispatch(auth.actions.logout()).then(() => {
      navigate('/dashboard/login');
    });
  };

  const handleNavigate = (e) => {
    const route = e.target.id;
    navigate(`${route === 'dashboard' ? `/${route}` : `/dashboard/${route}`}`);
    handleClose(e);
  };

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        ref={anchorRef}
        onClick={handleToggle}
        color="inherit"
      >
        <Avatar alt="Organization Logo" src={imageUrl || pintunaAvatar} />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem id="profile" onClick={handleNavigate}>
                    Profile
                  </MenuItem>

                  <Box py={1}>
                    <Divider />
                  </Box>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AvatarMenu;
