import React from 'react';
import {
  Grid,
  CardMedia,
  Toolbar,
  AppBar,
  IconButton,
  Container,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'gatsby-theme-material-ui';
import PropTypes from 'prop-types';

import logoPath from '../../images/logo.png';

const Header = ({ backArrow, href }) => {
  return (
    <Container disableGutters>
      <AppBar component="header" position="sticky" elevation={0}>
        <Toolbar disableGutters>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={11}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {backArrow ? (
                  <Link to={href}>
                    <IconButton aria-label="back">
                      <ArrowBack />
                    </IconButton>
                  </Link>
                ) : (
                  <Grid item xs={4} md={1}>
                    <Link to={href}>
                      <CardMedia
                        src={logoPath}
                        component="img"
                        alt="Pintuna logo"
                        style={{ width: '120px' }}
                      />
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

Header.defaultProps = {
  backArrow: false,
  href: '/',
};

Header.propTypes = {
  backArrow: PropTypes.bool,
  href: PropTypes.string,
};

export default Header;
