import React from 'react';
import {
  Divider,
  Drawer,
  Hidden,
  makeStyles,
  useTheme,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  Grid,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
  useScrollTrigger,
  Slide,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';

import SidebarMenu from './menus/sidebarMenu';
import AvatarMenu from './menus/avatarMenu';
import logoPath from '../../images/logo.png';
import { auth, businesses, enterprise } from '../../state';
import profileImage from '../../images/profile.png';

import LanguageSelect from '../custom/LanguageSelect/LanguageSelect';

const drawerWidth = '240px';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(30),
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerPaper: {
    minWidth: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const sidebarHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const trigger = useScrollTrigger();
  const isLoading = useSelector((state) =>
    auth.selectors.selectIsLoading(state)
  );

  const info = useSelector((state) => enterprise.selectors.selectInfo(state));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const giftCardConfigs = useSelector(
    enterprise.selectors.selectGiftCardConfigs
  );

  const business = useSelector(businesses.selectors.selectBusiness);
  const merchant = useSelector(businesses.selectors.selectMerchant);

  return (
    <div className={classes.root}>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          component="header"
          position="fixed"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.appToolbar}>
            <Grid container direction="row" justifyContent="flex-end">
              {!sm ? (
                <>
                  <Grid item xs={6}>
                    <Link to="/dashboard">
                      <Box style={{ width: 120, height: 60, padding: 2 }}>
                        <CardMedia
                          src={
                            merchant?.logoUrl ||
                            (giftCardConfigs.length === 1
                              ? business?.logoUrl || logoPath
                              : logoPath)
                          }
                          component="img"
                          alt="Pintuna logo"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Box align="right">
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                      >
                        <Menu fontSize="large" />
                      </IconButton>
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  {isLoading ? (
                    <CircularProgress size={26} />
                  ) : (
                    <>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Grid item style={{ marginRight: '20px' }}>
                          <LanguageSelect />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            component="span"
                            align="center"
                            style={{ padding: '6px' }}
                          >
                            {info.name}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <AvatarMenu imageUrl={profileImage} />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Toolbar>
          <Divider />
        </AppBar>
      </Slide>
      <nav className={classes.drawer} aria-label="navigation">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SidebarMenu />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <SidebarMenu />
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};
export default sidebarHeader;
