import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Container, Box, Grid } from '@material-ui/core';
import { AppStoreFooter, DefaultFooter } from './footer/';

const Footer = ({ appStore, showBlock }) => {
  return (
    <Box
      component="footer"
      display={{ xs: showBlock ? 'block' : 'none', md: 'block' }}
    >
      <Container>
        <AppBar
          component="div"
          color="secondary"
          position="static"
          elevation={0}
        >
          <Toolbar disableGutters>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={9}>
                <Box py={3}>
                  {appStore ? <AppStoreFooter /> : <DefaultFooter />}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Container>
    </Box>
  );
};

Footer.defaultProps = {
  appStore: false,
  showBlock: false,
};

Footer.propTypes = {
  appStore: PropTypes.bool,
  showBlock: PropTypes.bool,
};

export default Footer;
