import React, { useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardMedia,
  makeStyles,
  Avatar,
  Divider,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  CircularProgress,
  // Grid,
  // Badge,
  // withStyles,
  Collapse,
} from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import {
  Dashboard,
  AssignmentInd,
  Edit,
  PowerSettingsNew,
  CardGiftcardOutlined,
  ExpandLess,
  ExpandMore,
  Fingerprint,
  History,
  Receipt,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { auth, businesses, enterprise } from '../../../state';
import logoPath from '../../../images/logo.png';
import getCurrentRoute from '../../../utils/getCurrentRoute';

// const StyledBadge = withStyles((theme) => ({
//   anchorOriginTopRightRectangle: {
//     right: '-20px',
//     top: '6px',
//     fontSize: '8px',
//     color: theme.palette.text.secondary,
//   },
// }))(Badge);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    width: '240px',
    padding: theme.spacing(0, 1),
    // // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const SidebarMenu = () => {
  const [open, setOpen] = React.useState({
    cards: true,
    recipients: true,
    history: true,
  });
  const location = useLocation();
  const currentRoute = getCurrentRoute(location.pathname);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  // const user = useSelector(auth.selectors.selectUser);
  // useEffect(() => {
  //   if (user.merchantId) {
  //     dispatch(businesses.actions.fetchBusiness(user.merchantId));
  //   }
  // }, JSON.stringify(user));
  useEffect(() => {
    dispatch(enterprise.actions.getGiftCards());
  }, []);
  const giftCardConfigs = useSelector(
    enterprise.selectors.selectGiftCardConfigs
  );
  const enterpriseLoading = useSelector(enterprise.selectors.selectIsLoading);
  useEffect(() => {
    if (giftCardConfigs.length === 1) {
      dispatch(businesses.actions.getGiftCardConfig(giftCardConfigs[0]?.id));
    }
  }, [giftCardConfigs]);

  const handleClick = (e, id) => {
    setOpen({ ...open, [id]: !open[id] });
  };

  const isLoading = useSelector((state) =>
    auth.selectors.selectIsLoading(state)
  );

  const handleLogout = async () => {
    await dispatch(auth.actions.logout());
    navigate('/dashboard/login');
  };

  const { t } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);

  const merchant = useSelector(businesses.selectors.selectMerchant);
  const fetchMerchant = (p) => dispatch(businesses.actions.fetchMerchant(p));
  const host = typeof window !== 'undefined' ? window?.location?.origin : '';
  const hostNames =
    typeof window !== 'undefined' ? window?.location?.hostname?.split('.') : [];
  useEffect(() => {
    if (host && !host.includes('pintuna')) {
      fetchMerchant(encodeURIComponent(host));
    } else if (hostNames?.length > 3) {
      fetchMerchant(hostNames[0]);
    }
  }, []);
  return (
    <>
      <div className={classes.toolbar}>
        {sm ? (
          <Box width="100%" align="middle">
            <Link to="/dashboard">
              {enterpriseLoading && !giftCardConfigs.length ? (
                <CircularProgress size={24} />
              ) : (
                <Box style={{ width: 120, height: 60, padding: 2 }}>
                  <CardMedia
                    src={
                      merchant?.logoUrl ||
                      (giftCardConfigs.length === 1
                        ? business?.logoUrl || logoPath
                        : logoPath)
                    }
                    component="img"
                    alt="Pintuna logo"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              )}
            </Link>
          </Box>
        ) : (
          <>
            <Avatar
              alt="Organization Logo"
              src={
                merchant?.logoUrl ||
                (giftCardConfigs.length === 1
                  ? business?.logoUrl || logoPath
                  : logoPath)
              }
            />
            <Box pl={2}>
              <Typography>{merchant.name || 'Pintuna Inc.'}</Typography>
            </Box>
          </>
        )}
      </div>
      {sm ? <Divider /> : null}
      <List component="nav">
        <Link to="/dashboard" underline="none">
          <ListItem button selected={currentRoute === 'dashboard'}>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary={t('dashboard')} />
          </ListItem>
        </Link>
        {/* <Box px={2}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
                align="center"
              >
                Send Gift Cards
                Manage Recipients
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Divider />
            </Grid>
          </Grid>
        </Box> */}

        {/* Send Gift Cards */}
        <ListItem button onClick={(e) => handleClick(e, 'cards')}>
          <ListItemIcon>
            <CardGiftcardOutlined />
          </ListItemIcon>
          <ListItemText primary={t('sendGiftCards')} />
          {open.cards ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.cards} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding className={classes.nested}>
            <Link to="/dashboard/send-gift-cards-employees" underline="none">
              <ListItem
                button
                selected={currentRoute === 'send-gift-cards-employees'}
              >
                <ListItemIcon>
                  <AssignmentInd />
                </ListItemIcon>
                <ListItemText primary={t('employees')} />
              </ListItem>
            </Link>
            {/* <Link to="/dashboard/send-gift-cards-customers" underline="none"> */}
            {/*   <ListItem */}
            {/*     button */}
            {/*     selected={currentRoute === 'send-gift-cards-customers'} */}
            {/*   > */}
            {/*     <ListItemIcon> */}
            {/*       <PeopleAlt /> */}
            {/*     </ListItemIcon> */}
            {/*     <ListItemText primary="Customers" /> */}
            {/*   </ListItem> */}
            {/* </Link> */}
            {/* <Link to="/dashboard/send-gift-cards-bulk" underline="none"> */}
            {/*   <ListItem */}
            {/*     button */}
            {/*     selected={currentRoute === 'send-gift-cards-bulk'} */}
            {/*   > */}
            {/*     <ListItemIcon> */}
            {/*       <Person /> */}
            {/*     </ListItemIcon> */}
            {/*     <ListItemText primary="Bulk" /> */}
            {/*   </ListItem> */}
            {/* </Link> */}
          </List>
          <Divider />
        </Collapse>

        {/* Manage recipients */}
        {handleClick === 0 && (
          <>
            <ListItem button onClick={(e) => handleClick(e, 'recipients')}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Manage" />
              {open.recipients ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open.recipients} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding className={classes.nested}>
                <Link to="/dashboard/manage-employees" underline="none">
                  <ListItem
                    button
                    selected={currentRoute === 'manage-employees'}
                  >
                    <ListItemIcon>
                      <AssignmentInd />
                    </ListItemIcon>
                    <ListItemText primary="Employees" />
                  </ListItem>
                </Link>
                {/* <Link to="/dashboard/manage-customers" underline="none"> */}
                {/*   <ListItem button selected={currentRoute === 'manage-customers'}> */}
                {/*     <ListItemIcon> */}
                {/*       <PeopleAlt /> */}
                {/*     </ListItemIcon> */}
                {/*     <ListItemText primary="Customers" /> */}
                {/*   </ListItem> */}
                {/* </Link> */}
              </List>
              <Divider />
            </Collapse>
          </>
        )}

        {/* HISTORY */}
        <ListItem button onClick={(e) => handleClick(e, 'history')}>
          <ListItemIcon>
            <History />
          </ListItemIcon>
          <ListItemText primary={t('history')} />
          {open.history ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.history} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding className={classes.nested}>
            <Link to="/dashboard/transaction-history" underline="none">
              <ListItem
                button
                selected={currentRoute === 'transaction-history'}
              >
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText primary="Transactions" />
              </ListItem>
            </Link>
          </List>
          <Divider />
        </Collapse>
        {!sm ? (
          <>
            <Divider />
            <Link to="/dashboard/profile" underline="none">
              <ListItem button selected={currentRoute === 'profile'}>
                <ListItemIcon>
                  <Fingerprint />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
            </Link>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <PowerSettingsNew />
                )}
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        ) : null}
      </List>
    </>
  );
};

export default SidebarMenu;
