import React from 'react';
import { Container as MUIContainer, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const Content = ({ children, md, alignMiddle, withSidebar }) => {
  return withSidebar ? (
    <Box
      py={{ xs: 2 }}
      px={{ xs: 2, sm: 3 }}
      mt={{ xs: 7, md: 8 }}
      ml={{ sm: 30 }}
    >
      {/* adjustment for sidebar and header */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={md}>
          {children}
        </Grid>
      </Grid>
    </Box>
  ) : (
    <MUIContainer component="main" style={{ minHeight: 'calc(100vh - 256px)' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: alignMiddle ? 'calc(100vh - 256px)' : '100%',
        }}
      >
        <Grid item xs={12} md={md}>
          {children}
        </Grid>
      </Grid>
    </MUIContainer>
  );
};

Content.defaultProps = {
  md: 9,
  alignMiddle: false,
  withSidebar: false,
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  md: PropTypes.number,
  alignMiddle: PropTypes.bool,
  withSidebar: PropTypes.bool,
};

export default Content;
